import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import SEO from '../../components/seo'
import BannerSection from '../../components/BannerSection'
import Section from '../../components/Common/Section'
import Container from '../../components/Common/Container'
import Typography from '../../components/Common/Typography'
import NewsQuoteBlock from '../../components/NewsQuoteBlock'

import * as s from '../../pages/news.module.scss'

const PrichinyIspolsovatInspection: React.FC = () => {
  const images = useStaticQuery(imagesQuery)

  return (
    <>
      <SEO
        title="6 причин использовать Фото360 на стройке"
        description="Использование Фото360 на стройке для удаленного строительного контроля набирает популярность во всем мире, в России нет аналогов сервису SIGNAL INSPECTION, который позволяет создавать Фото360 с привязкой к планам"
      />
      <BannerSection
        imageDesktop={images.banner.childImageSharp.gatsbyImageData}
        title={`6 причин цифровизациИ строительного контроля с SIGNAL INSPECTION`}
        subTitle={`Удаленный и цифровой мониторинг строительных площадок с использованием фото360`}
        titleSize={60}
        imgStyle={{ height: '467px' }}
      />
      <Section>
        <Container>
          <Typography variant="body1" size={18} mb={24}>
            Говоря про панорамную съемку или фото360 у многих возникает
            ассоциация с Google или Яндекс картами. Но SIGNAL INSPECTION - это
            не просто сервис для создания панорамных фотографий, это возможность
            использовать фото360 на строительной площадке с привязкой точек
            съемки к планам и сравнением фото360 от разных дат. Это первый шаг к
            цифровизации строительного контроля, сделать его более гибким и
            функциональным.
          </Typography>
          <Typography variant="body1" size={18} mb={32}>
            Мы подготовили для вас 6 не очевидных кейса использования SIGNAL
            INSPECTION на ваших строительных объектах.
          </Typography>
          <Typography variant="h3" size={32} color="blue" mb={32}>
            1. Оценка выполнения работ на строительной площадке
          </Typography>
          <Typography variant="body1" size={18} mb={24}>
            Не всегда инженеры ПТО могут выбраться из офиса на объект, чтобы
            подтвердить факт выполнения и объем работ. С помощью INSPECTION
            достаточно выбрать точку съемки в месте выполнения и
            проверить/подтвердить факт выполнения работ, качество и примерный
            объем выполненных работ за неделю.
          </Typography>
          <div className={s.imageWrap}>
            <StaticImage
              width={1080}
              src="../../assets/images/blog/6-prichin-ispolsovat-inspection/image-1.png"
              alt="Подтверждение факта выполнения работ по Фото360"
              title="Подтверждение факта выполнения работ по Фото360"
              placeholder="blurred"
            />
            <Typography variant="body2" color="gray">
              Подтверждение факта выполнения работ по Фото360
            </Typography>
          </div>
          <Typography variant="h3" size={32} color="blue" mb={32}>
            2. Подтверждение скрытых работ
          </Typography>
          <Typography variant="body1" size={18} mb={24}>
            Имея на руках только одну модель, сложно подтвердить или отследить
            факт проведения работ, после отделочных работ. С INSPECTION у вас
            есть фото ДО и фото ПОСЛЕ, например при закрытии коммуникаций
            отделочными материалами и по Фото360 всегда можно определить, какие
            коммуникации проходят за стеной или обшивкой потолка.
          </Typography>
          <div className={s.imageWrap}>
            <StaticImage
              width={850}
              src="../../assets/images/blog/6-prichin-ispolsovat-inspection/image-2.png"
              alt="Сравнение Фото360 до и после монтажа"
              title="Сравнение Фото360 до и после монтажа"
              placeholder="blurred"
            />
            <Typography variant="body2" color="gray">
              Сравнение Фото360 до и после монтажа
            </Typography>
          </div>
          <Typography variant="h3" size={32} color="blue" mb={32}>
            3. Контроль чистоты и безопасности на площадке
          </Typography>
          <Typography variant="body1" size={18} mb={24}>
            Отследить причину невыполненных работ, складируемый мусор или выдать
            предписание за нарушения техники безопасности теперь не проблема.
            INSPECTION позволяет выявить те нарушения, которые не были замечены
            при обходе строительной площадки.
          </Typography>
          <div className={s.imageWrap}>
            <StaticImage
              width={850}
              src="../../assets/images/blog/6-prichin-ispolsovat-inspection/image-3.png"
              alt="Сравнение Фото360 до и после устраненного замечания"
              title="Сравнение Фото360 до и после устраненного замечания"
              placeholder="blurred"
            />
            <Typography variant="body2" color="gray">
              Сравнение Фото360 до и после устраненного замечания
            </Typography>
          </div>
          <Typography variant="h3" size={32} color="blue" mb={32}>
            4. Визуальный журнал событий на стройке
          </Typography>
          <Typography variant="body1" size={18} mb={24}>
            Отслеживайте изменения и выполненные объемы по Фото360. INSPECTION
            позволяет, например, отследить какой объем работ выполнила бригада
            до своего ухода с площадки и какой объем предстоит новому
            подрядчику.
          </Typography>
          <div className={s.imageWrap}>
            <StaticImage
              width={1080}
              src="../../assets/images/blog/6-prichin-ispolsovat-inspection/image-4.png"
              alt="Фиксация не выполненного объема работ"
              title="Фиксация не выполненного объема работ"
              placeholder="blurred"
            />
            <Typography variant="body2" color="gray">
              Фиксация не выполненного объема работ
            </Typography>
          </div>
          <Typography variant="h3" size={32} color="blue" mb={32}>
            5. Разрешение конфликтных ситуаций во время совещаний
          </Typography>
          <Typography variant="body1" size={18} mb={24}>
            Фото360 позволяют оказаться на стройплощадке находясь в офисе и
            визуализировать то, что вы обсуждаете с коллегами. C SIGNAL
            INSPECTION совещания становятся более виртуальными и информативными,
            меньше времени на споры и больше на решение ключевых проблем и
            задач.
          </Typography>
          <div className={s.imageWrap}>
            <StaticImage
              width={850}
              src="../../assets/images/blog/6-prichin-ispolsovat-inspection/image-5.png"
              alt="Совместное использование INSPECTION и DASHBOARD на совещаниях"
              title="Совместное использование INSPECTION и DASHBOARD на совещаниях"
              placeholder="blurred"
            />
            <Typography variant="body2" color="gray">
              Совместное использование INSPECTION и DASHBOARD на совещаниях
            </Typography>
          </div>
          <Typography variant="h3" size={32} color="blue" mb={32}>
            6. Демонстрация BIM-моделей по точкам на плане
          </Typography>
          <Typography variant="body1" size={18} mb={24}>
            SIGNAL INSPECTION работает не только с Фото360, но и с панорамными
            точками обзора BIM-моделей. Вы можете подготовить план этажа с
            точками съемки, в Navisworks подготовить точки обзара и
            экспортировать их в INSPECTION. Далее по этим моделям можно создать
            альбом и расшарить его заказчику для ознакомления, причем установка
            дополнительного ПО для этого не требуется. Или другой сценарий -
            авторский надзор, сравнение проектных решений с фактом выполнения по
            Фото360
          </Typography>
          <div className={s.imageWrap}>
            <StaticImage
              width={850}
              src="../../assets/images/blog/6-prichin-ispolsovat-inspection/image-6.png"
              alt="Сравнение проектных решений с фактом выполнения по Фото360"
              title="Сравнение проектных решений с фактом выполнения по Фото360"
              placeholder="blurred"
            />
            <Typography variant="body2" color="gray">
              Сравнение проектных решений с фактом выполнения по Фото360
            </Typography>
          </div>
          <NewsQuoteBlock
            avatar={images.author.childImageSharp.gatsbyImageData}
            name="Сергей Киселев"
            position="Руководитель BIM отдела и разработки ПО, ГК GREEN"
            text="“Здесь однозначно все голосуют двумя руками за SIGNAL INSPECTION, к функционалу замечаний нет. И самим удобно работать, и всем заказчикам нравится удобство работы.”"
          />
          <Typography variant="h4" size={24} mb={16}>
            Заключение
          </Typography>
          <Typography variant="body1" size={18}>
            SIGNAL INSPECTION - довольно простой и функциональный инструмент, у
            которого нет аналогов на российском рынке, что делает его
            необходимым решением для использования на строительных объектах. Мы
            представили вам 6 примеров, в которых SIGNAL INSPECTION становится
            must have на любой строительной площадке. Будь то оценка выполнения
            работ, подтверждение скрытых работ, контроль безопасности и чистоты,
            ведение визуального журнала событий или разрешение конфликтных
            ситуаций на совещаниях - SIGNAL INSPECTION дает вам возможность быть
            в курсе происходящего, всегда иметь доступ к актуальным данным и
            принимать взвешенные решения.
          </Typography>
        </Container>
      </Section>
    </>
  )
}

export default PrichinyIspolsovatInspection

const imagesQuery = graphql`
  query {
    banner: file(
      relativePath: { eq: "blog/6-prichin-ispolsovat-inspection/banner.jpeg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
    author: file(
      relativePath: {
        eq: "blog/6-prichin-ispolsovat-inspection/Сергей-Киселев.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 100, placeholder: BLURRED)
      }
    }
  }
`
